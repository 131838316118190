
  import { Component, Vue } from 'vue-property-decorator';
@Component
export default class HomeView extends Vue {

    newTitles: any = []
    categories: any = []


    async getNewTitles () {
      const { data } = await this.axios.get('/titles/new')
      // const { data } = await this.axios.get('http://localhost/api/titles/new')

      return data
    }
     

    async getCategories () {
      const { data } = await this.axios.get('/categories')
      // const { data } = await this.axios.get('http://localhost/api/categories')

      return data 

    }


    async mounted() {
      this.newTitles = await this.getNewTitles()
      this.categories = await this.getCategories()
      // console.log(this.newTitles)
      // console.log(this.categories)



    }

    message="こんにちは。今日も勉強をがんばろう‼︎"

  }
