
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  //下を追加
  // components: {

  // },

  data: () => ({
    //
  }),
});
